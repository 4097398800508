import React from 'react'
import {FormattedMessage} from 'react-intl';

const factor = 0.393701;


export default class Calculator extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            valueInCM: Math.round(1/factor*100)/100,
            valueInMM: Math.round(10/factor*100)/100,
            valueInInch: 1
        };
        this.handleCMChange = this.handleCMChange.bind(this);
        this.handleMMChange = this.handleMMChange.bind(this);
        this.handleInchChange = this.handleInchChange.bind(this);
    }

    handleCMChange(event) {
        let cm = parseFloat(event.target.value.replace(",","."));
        this.setState({
            valueInInch: isNaN(cm) ? "" : Math.round(cm*factor*100)/100,
            valueInCM: event.target.value,
            valueInMM: isNaN(cm) ? "" : Math.round(cm*1000)/100
        });
    }

    handleMMChange(event) {
        let mm = event.target.value.replace(",",".");
        this.setState({
            valueInInch: isNaN(mm) ? "" : Math.round(mm*factor*100)/100,
            valueInCM: isNaN(mm) ? "" : Math.round(mm*10)/100,
            valueInMM: event.target.value
        });
    }

    handleInchChange(event) {
        let inch = event.target.value.replace(",",".");
        this.setState({
            valueInInch: event.target.value,
            valueInCM: isNaN(inch) ? "" : Math.round(inch/factor*100)/100,
            valueInMM: isNaN(inch) ? "" : Math.round(inch/factor*1000)/100
        });
    }

    render()
    {
        return (
                <div className="bg-blue-50 py-12 text-blue-900">
                    <div className="container">
                        <h2 className={'text-center mb-4 text-3xl'}><FormattedMessage id={"unit-converter.title"}/></h2>
                        <h3 className={'text-center mb-12 text-xl font-medium'}><FormattedMessage id={"unit-converter.sub-title"}/>
                        </h3>

                            <div className="row text-center justify-center">
                            <div>
                                <div className={"font-bold"}>inch</div>
                                <input className={'border rounded p-4 text-xl text-center shadow border-blue-900'}
                                       type="text"
                                       style={{maxWidth:'7em'}}
                                       value={this.state.valueInInch}
                                       onChange={this.handleInchChange}
                                />
                                </div>
                            <div className={"pt-8 px-4 text-xl mt-1"}>=</div>
                            <div>
                                <div className={"font-bold"}>cm</div>
                                <input className={'border rounded p-4 text-xl text-center shadow border-blue-900'}
                                       type="text"
                                       style={{maxWidth:'7em'}}
                                       value={this.state.valueInCM}
                                       onChange={this.handleCMChange}
                                />
                            </div>
                            <div className={"pt-8 px-4 text-xl mt-1"}>=</div>
                            <div>
                                <div className={"font-bold"}>mm</div>
                                <input className={'border rounded p-4 text-xl text-center shadow border-blue-900'}
                                       type="text"
                                       style={{maxWidth:'7em'}}
                                       value={this.state.valueInMM}
                                       onChange={this.handleMMChange}
                                />
                            </div>
                        </div>
                        <p className={'text-center mt-4 text-blue-800'}>
                            <FormattedMessage id={"unit-converter.instructions"}/>

                        </p>

                    </div>

                </div>
        )
            ;
    }
}

