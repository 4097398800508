import React from 'react'
import Layout from '../../components/layout';
import Calculator from '../../components/unit-calculator';

export default class IndexPage extends React.Component {

    render()
    {
        return (
            <Layout lang={'de'}
                    title={'Einheiten-Umrechner für Stickmuster und Stickrahmen'}
                    description={'Online Einheiten-Rechner um Größen zwischen Inch, cm und mm zu wandeln. Dazu gibt\'s noch eine Tabelle mit gängigen Stickrahmen-Größen.'}
                    translated={{en:'/calculator/'}}
                    location={this.props.location.pathname}
            >

                <Calculator/>

                <div className="container mx-auto mt-12 text-center">
                    <h2 className={"text-2xl mb-4"}>Übliche Stickrahmen-Größen:</h2>

                    <table  className="table text-right table-striped mx-auto">
                        <thead>
                        <tr>
                            <th>Größe in inch</th>
                            <th>Größe in mm</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>4 x 4 inch</td>
                            <td>100 x 100 mm</td>
                        </tr>
                        <tr>
                            <td>5 x 7 inch</td>
                            <td>130 x 180 mm</td>
                        </tr>
                        <tr>
                            <td>6 x 10 inch</td>
                            <td>160 x 260 mm</td>
                        </tr>
                        <tr>
                            <td>8 x 10 inch</td>
                            <td>200 x 260 mm</td>
                        </tr>
                        <tr>
                            <td>8 x 8 inch</td>
                            <td>200 x 200 mm</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </Layout>
        )
            ;
    }
}
